import { useLocales } from '@sevenrooms/core/locales'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, VStack, Box, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { emailPreferenceCenterMessages } from '../emailPreferenceCenterMessages'
import { useStoreSelector } from '../store'

interface ConfirmationPageProps {
  confirmationText: string
}

export function ConfirmationPage({ confirmationText }: ConfirmationPageProps) {
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  const clientEmail = useStoreSelector(state => state.clientEmail)

  return (
    <CardSection maxWidth="500px" p="lm">
      <HStack>
        <Box pr="s" pt="xs">
          <Icon name="GX-checkbox-marked-circle" size="lg" color="successText" />
        </Box>
        <VStack spacing="xs">
          <Text textStyle={isMobile ? 'h2' : 'h1'}>
            {formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterConfirmationPageTitle)}
          </Text>
          <SecondaryText>
            {formatMessage(emailPreferenceCenterMessages.clientEmailLabel)}
            {': '}
            {clientEmail}
          </SecondaryText>
          <Box pt="m">
            <Text fontSize={isMobile ? 'm' : 'l'}>{confirmationText}</Text>
          </Box>
        </VStack>
      </HStack>
    </CardSection>
  )
}
