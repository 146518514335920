import { combineReducers } from 'redux'

export const rootReducer = combineReducers({
  venueInfo: (state = {}) => state,
  selectedLanguageStrings: (state = {}) => state,
  widgetSettings: (state = {}) => state,
  clientEmail: (state = {}) => state,
  clientId: (state = {}) => state,
  marketingOptIns: (state = {}) => state,
  campaignId: (state = {}) => state,
  emailCampaignSubscriptions: (state = {}) => state,
  currentVenueOptIn: (state = {}) => state,
  allLocationOptIn: (state = {}) => state,
})
