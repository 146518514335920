import { type PropsWithChildren, useMemo, useState } from 'react'
import { Provider } from 'react-redux'
import { Locale } from '@sevenrooms/core/locales'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Root, Flex, VStack, LogoFooter, TopNav, logoFooterMessages } from '@sevenrooms/core/ui-kit/layout'
import { ClientEmailPreferencesPage, EmailCampaignSubscription, ConfirmationPage } from './components'
import { emailPreferenceCenterMessages } from './emailPreferenceCenterMessages'
import { store, useStoreSelector } from './store'

function AppRoot({ children }: PropsWithChildren<{}>) {
  const { venueColor, locale } = useStoreSelector(state => state.venueInfo)
  const selectedStrings = useStoreSelector(state => state.selectedLanguageStrings)
  const widgetSettings = useStoreSelector(state => state.widgetSettings)
  const brandColor = widgetSettings.colorPrimary
  const venueLocale = Locale.getLocale(locale)

  const messages = useMemo(
    () => ({
      ...selectedStrings,
      [emailPreferenceCenterMessages.allLocation.id]: emailPreferenceCenterMessages.allLocation.defaultMessage,
      [emailPreferenceCenterMessages.unsubscribeFromAllLocation.id]:
        emailPreferenceCenterMessages.unsubscribeFromAllLocation.defaultMessage,
      [logoFooterMessages.footerLabel.id]: selectedStrings.resWidgetPoweredByLabel,
    }),
    [selectedStrings]
  )

  const themeOverride = useMemo(
    () => ({
      colors: {
        brandColor,
        venueColor,
      },
    }),
    [brandColor, venueColor]
  )

  return (
    <Root theme="gx" themeOverride={themeOverride} messages={messages} locale={venueLocale}>
      {children}
    </Root>
  )
}

function EmailPreferenceContainer() {
  const { website, name, largeLogoUrl } = useStoreSelector(state => state.venueInfo)
  const isMobile = useMaxWidthBreakpoint('m')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [confirmationText, setConfirmationText] = useState('')
  const emailCampaignSubscriptions = useStoreSelector(state => state.emailCampaignSubscriptions)
  return (
    <VStack justifyContent="space-between" minHeight="100vh">
      <VStack width="100%">
        <TopNav baseUrl={website} logoLabel={name} logo={largeLogoUrl} />
        <Flex width="100%" justifyContent="center" pt={isMobile ? 'l' : 'xxl'} pl="lm" pr="lm">
          {!isSubmitted &&
            (emailCampaignSubscriptions ? (
              <EmailCampaignSubscription toggleConfirmationStatus={setIsSubmitted} setConfirmationText={setConfirmationText} />
            ) : (
              <ClientEmailPreferencesPage toggleConfirmationStatus={setIsSubmitted} setConfirmationText={setConfirmationText} />
            ))}
          {isSubmitted && <ConfirmationPage confirmationText={confirmationText} />}
        </Flex>
      </VStack>
      <LogoFooter />
    </VStack>
  )
}

export function App() {
  return (
    <Provider store={store}>
      <AppRoot>
        <EmailPreferenceContainer />
      </AppRoot>
    </Provider>
  )
}
