import { configureStore } from '@reduxjs/toolkit'
import { type TypedUseSelectorHook, useSelector } from 'react-redux'
import { preloadedState, type PreloadedState } from './preloadedState'
import { rootReducer } from './reducers'

export const store = configureStore({
  reducer: rootReducer,
  preloadedState,
})

export const useStoreSelector: TypedUseSelectorHook<PreloadedState> = useSelector
