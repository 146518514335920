import { EmailPreferenceCenterAdapter, type EmailPreferenceWindow } from '@sevenrooms/core/api'

declare let window: EmailPreferenceWindow

export const preloadedState = {
  venueInfo: EmailPreferenceCenterAdapter.venueToClient(window.PRELOADED.base_venue),
  widgetSettings: EmailPreferenceCenterAdapter.settingsToClient(window.PRELOADED.widget_settings),
  selectedLanguageStrings: EmailPreferenceCenterAdapter.stringsToClient(window.PRELOADED.selected_language_strings),
  clientEmail: window.PRELOADED.client_email,
  clientId: window.PRELOADED.client_id,
  marketingOptIns: window.PRELOADED.marketing_opt_ins,
  currentVenueOptIn: window.PRELOADED.current_venue_opt_in,
  campaignId: window.PRELOADED.campaign_id,
  emailCampaignSubscriptions: window.PRELOADED.email_campaign_subscriptions,
  allLocationOptIn: window.PRELOADED.all_location_opt_in,
}

export type PreloadedState = typeof preloadedState
