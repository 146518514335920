import { defineMessages } from '@sevenrooms/core/locales'

export const emailPreferenceCenterMessages = defineMessages({
  emailPreferenceCenterHeader: {
    id: 'emailPreferenceCenterHeader',
    defaultMessage: 'Manage Subscription',
  },
  emailPreferenceCenterDescription: {
    id: 'emailPreferenceCenterDescription',
    defaultMessage: 'Choose which location(s) you would like to receive marketing communication from:',
  },
  emailPreferenceCenterSaveButton: {
    id: 'emailPreferenceCenterSaveButton',
    defaultMessage: 'Update Preferences',
  },
  emailPreferenceCenterUnsubscribeButton: {
    id: 'emailPreferenceCenterUnsubscribeButton',
    defaultMessage: 'Unsubscribe from All',
  },
  emailPreferenceCenterConfirmationPageTitle: {
    id: 'emailPreferenceCenterConfirmationPageTitle',
    defaultMessage: 'Email Preferences Updated',
  },
  emailPreferenceCenterConfirmationOptOut: {
    id: 'emailPreferenceCenterConfirmationOptOut',
    defaultMessage: 'We have updated your preferences',
  },
  emailPreferenceCenterConfirmationUnsubscribe: {
    id: 'emailPreferenceCenterConfirmationUnsubscribe',
    defaultMessage: 'You have unsubscribed from marketing communication from all of our locations',
  },
  clientEmailLabel: {
    id: 'resWidgetEmailLabel',
    defaultMessage: 'Email Address',
  },
  submissionError: {
    id: 'resConfirmPageError',
    defaultMessage: 'There seems to be an error',
  },
  allLocation: {
    id: 'allLocation',
    defaultMessage: 'All Location',
  },
  unsubscribeFromAllLocation: {
    id: 'unsubscribeFromAllLocation',
    defaultMessage: 'Unsubscribe from All Locations',
  },
} as const)
