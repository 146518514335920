import { useMemo, useState } from 'react'
import { useUpdateEmailPreferenceMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, CheckboxGroup, type CheckboxChoice, type CheckboxChoiceValue } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { VStack, Flex, notify, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { emailPreferenceCenterMessages } from '../emailPreferenceCenterMessages'
import { useStoreSelector } from '../store'

interface EmailPreferencesPageProps {
  toggleConfirmationStatus: (value: boolean) => void
  setConfirmationText: (value: string) => void
}

export function ClientEmailPreferencesPage({ toggleConfirmationStatus, setConfirmationText }: EmailPreferencesPageProps) {
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  const marketingOptIns = useStoreSelector(state => state.marketingOptIns)
  const clientEmail = useStoreSelector(state => state.clientEmail)
  const clientId = useStoreSelector(state => state.clientId)
  const campaignId = useStoreSelector(state => state.campaignId)
  const { preferenceOptions, venueOptIns, venueOptOuts, venueIds } = useMemo(
    () => generatePreferenceData(marketingOptIns),
    [marketingOptIns]
  )
  const [updateEmailPreference, { isLoading }] = useUpdateEmailPreferenceMutation()

  const [selectedOptIns, setSelectedOptIns] = useState<Set<string>>(new Set(venueOptIns))
  const [selectedOptOuts, setSelectedOptOuts] = useState<Set<string>>(new Set(venueOptOuts))

  const handlePreferenceChange = (selectedChoices: CheckboxChoiceValue[]) => {
    const selectedVenues = new Set(selectedChoices as string[])
    setSelectedOptIns(selectedVenues)
    setSelectedOptOuts(new Set(venueIds.filter(venueId => !selectedVenues.has(venueId))))
  }

  const onPreferenceUpdate = async () => {
    const preferenceUpdatePromise = updateEmailPreference({
      clientId,
      venueOptIns: [...selectedOptIns],
      venueOptOuts: [...selectedOptOuts],
      campaignId,
      sendDoubleOptInEmail: false,
    })
    try {
      await preferenceUpdatePromise
      toggleConfirmationStatus(true)
      setConfirmationText(formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterConfirmationOptOut))
    } catch {
      notify({
        content: formatMessage(emailPreferenceCenterMessages.submissionError),
        type: 'error',
      })
    }
  }

  const onUnsubscribeFromAll = async () => {
    const preferenceUpdatePromise = updateEmailPreference({
      clientId,
      venueOptIns: [],
      venueOptOuts: venueIds,
      campaignId,
      sendDoubleOptInEmail: false,
    })
    try {
      await preferenceUpdatePromise
      toggleConfirmationStatus(true)
      setConfirmationText(formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterConfirmationUnsubscribe))
    } catch {
      notify({
        content: formatMessage(emailPreferenceCenterMessages.submissionError),
        type: 'error',
      })
    }
  }

  return (
    <CardSection maxWidth="500px" p="lm">
      <VStack spacing="lm">
        <VStack spacing="xs">
          <Text textStyle={isMobile ? 'h2' : 'h1'}>{formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterHeader)}</Text>
          <SecondaryText>{formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterDescription)}</SecondaryText>
        </VStack>
        <CheckboxGroup choices={preferenceOptions} onChange={handlePreferenceChange} selected={venueOptIns} />
        <SecondaryText>
          {formatMessage(emailPreferenceCenterMessages.clientEmailLabel)}
          {': '}
          {clientEmail}
        </SecondaryText>
        <Flex flexDirection={isMobile ? 'column' : 'row'} justifyContent="center" rowGap="m" columnGap="m">
          <Button onClick={onPreferenceUpdate} disabled={isLoading} fullWidth data-test="update-preference-button">
            {formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterSaveButton)}
          </Button>
          <Button onClick={onUnsubscribeFromAll} variant="secondary" disabled={isLoading} fullWidth data-test="unsubscribe-from-all-button">
            {formatMessage(emailPreferenceCenterMessages.emailPreferenceCenterUnsubscribeButton)}
          </Button>
        </Flex>
      </VStack>
    </CardSection>
  )
}

export const generatePreferenceData = (marketingOptIns: { [key: string]: [string, boolean | null] }) => {
  const preferenceOptions: CheckboxChoice<string>[] = []
  const venueOptIns: string[] = []
  const venueOptOuts: string[] = []
  const venueIds = Object.keys(marketingOptIns)
  const selectedVenueId = new URLSearchParams(window.location.search).get('v')

  venueIds.forEach(venueId => {
    const [venueName, isSubscribed] = marketingOptIns[venueId] as [string, boolean]
    const preferenceOption = {
      value: venueId,
      label: venueName,
    }
    preferenceOptions.push(preferenceOption)
    if (isSubscribed || selectedVenueId === venueId) {
      venueOptIns.push(venueId)
    } else {
      venueOptOuts.push(venueId)
    }
  })
  return {
    preferenceOptions,
    venueOptIns,
    venueOptOuts,
    venueIds,
  }
}
